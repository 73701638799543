import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LOGO from '../assets/logo.png';
import { logIn } from '../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [password, setPassword] = useState(localStorage.getItem('password') || '');
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe,') || false);
  const navigate = useNavigate();
  const dispatch = useDispatch();




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/users/login`, {
        email,
        password,
      });

      console.log(response.data)
      dispatch(logIn(response.data.token));

      // Store the token in the appropriate storage based on the rememberMe value
      if (rememberMe) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberMe', rememberMe);
      } else {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('password', password);
        sessionStorage.setItem('rememberMe', rememberMe);
      }


      navigate('/home', { replace: true });
    } catch (error) {

      toast.error('Login credentials must be entered correctly', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      console.error('An error occurred while logging in.', error);
    }
  };


  const handleEmail = e => setEmail(e.target.value)

  const handlePassword = e => setPassword(e.target.value)

  const handleRememberMe = (e) => setRememberMe(e.target.checked);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src={LOGO}
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
          <div>

            <div className="mt-2">
              <input
                id="email"
                onChange={handleEmail}
                name="email"
                type="email"
                autoComplete="email"
                placeholder='Email'
                required
                value={email}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            
            <div className="mt-2">
              <input
                id="password"
                onChange={handlePassword}
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder='Password'
                required
                value={password}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="flex gap-6">
           
          <div className='item-left w-1/2'>
              <label htmlFor="remember_me" className="block text-sm leading-5 text-gray-900">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 mr-2 text-slate-600 focus:ring-slate-500 border-gray-300 rounded"
                onChange={handleRememberMe}
                checked={rememberMe}
              />
                Remember me
              </label>
          
            </div>
             
              <div className='item-right w-1/2 text-right'>
                <Link to="/reset-password" className="font-semibold text-slate-600 hover:text-slate-500 text-sm">
                  Forgot password?
                </Link>
              </div>
            
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-slate-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{' '}
          <Link to="/register" className="font-semibold leading-6 text-slate-600 hover:text-slate-500">
            Register
          </Link>
        </p>
      </div>
      <ToastContainer />

    </div>
  )
}

export default Login
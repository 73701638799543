import React from 'react'
import { Link } from 'react-router-dom'
import LOGO from '../assets/logo.png'

const ResetPasswordMessage = () => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="w-full">
          <img
            className="mx-auto h-10 w-auto"
            src={LOGO}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Thank you for submitting your email address. We've sent a password reset link to your inbox. Please check your email and click on the link inside the email to reset your password. If you don't see the email in your inbox, be sure to check your spam or junk folder.

          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
         

          <p className="mt-10 text-center text-sm text-gray-500">
            Remembered Password?{' '}
            <Link to="/" className="font-semibold leading-6 text-slate-600 hover:text-slate-500">
              Login
            </Link>
          </p>
        </div>
      </div>
  )
}

export default ResetPasswordMessage
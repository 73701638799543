import { useState, useEffect, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Layout from '../components/Layout'
import Form from '../components/findpeople/Form'
import Table from '../components/findpeople/Table'
import axios from 'axios'
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode'
import _ from "lodash"
import PropagateLoader from "react-spinners/PropagateLoader";

const FindPeople = () => {
  const { token } = useSelector((state) => state.auth);
  const [user, setUser] = useState()
  const [leads, setLeads] = useState([])
  let [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [companyName, setCompanyName] = useState()
  const [loadingResult, setLoadingResult] = useState(false)
  
  const myRef = useRef(null);

  useEffect(() => {
    setUser(jwt(token.split(" ")[1]))
    console.log('Token in Find companies', jwt(token.split(" ")[1]))
  }, [token])



 
  const getRandomKey = () => {
    const maxKeyNumber = 3; // Replace with the number of keys you have
    const randomKeyNum = Math.floor(Math.random() * maxKeyNumber) + 1; // Generate a random number between 1 and maxKeyNumber
    return `REACT_APP_LPROFILE_KEY${randomKeyNum}`;
}

const randomKey = getRandomKey();


console.log("randomKey",process.env[randomKey])

  const handleProfiles = async (roleArray, companyId, companyName, country, role, countPeople) => {
    console.log("roleArray", roleArray)
    console.log("companyId", companyId)
    console.log("companyName", companyName)
    console.log("country", country)
    console.log("role", role)

    setCompanyName(companyName)

    setLoading(true)
    setOpen(true)

    let queryData = {
      page: 1,
      job_title: roleArray,
      company: [
        {
          value: companyId,
          label: companyName,
        },
      ],

      location: [
        {
          value: _.lowerCase(country),
          label: country,
        },
      ],
      not_job_title: [],
      not_location: [],
      employees: [],
      industry: [],
      hiring_for: [],
      revenue: "",
      keyword: "",
      name: "",
      technology: [],
      email_status: [],
      funding: [],
    };

    const options = {
      method: "POST",
      url: "https://generate-linkedin-leads.p.rapidapi.com/backend/leads/leads/",
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Key": process.env[randomKey],
        "X-RapidAPI-Host": "generate-linkedin-leads.p.rapidapi.com",
      },
      data: queryData,
    };

    try {
      const response = await axios.request(options);



      console.log("Linkedin Profiles Data", response.data.leads);

      let profileList = response.data.leads;
      let allProfilesList = [];

        _.map(profileList, (profile) => {
          let newProfileObject = {
            name: profile.name,
            company_name: profile.company_name,
            headline: profile.headline,
            location: profile.location,
            linkedin_url: profile.linkedin_url
            
          }
          allProfilesList.push(newProfileObject)

        })


        let saveProfileObject = {
          profileId: `PROFILES-${countPeople+1}`,
          user: user.id,
          company: companyName,
          country: country,
          profiles: allProfilesList,
          role: role
          
        };

        console.log("saveProfileObject:", saveProfileObject);


        try {
          const res = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/savedProfiles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token
            },
            body: JSON.stringify(saveProfileObject),
          });

          const Profilesdata = await res.json();

          if (res.ok) {
            // the HTTP response status code is 2xx
            // update your state with the data or do something else
            console.log(Profilesdata);

          } else {
            // the HTTP response status code is not 2xx
            // handle the error
            console.error(Profilesdata);
          }
        } catch (error) {
          // handle the error
          console.error('Error:', error);
        }

      setLeads(response.data.leads)

      setLoading(false)
      setOpen(false)
      setLoadingResult(true)
      myRef.current.scrollIntoView({ behavior: 'smooth' });

    } catch (error) {
      console.error(error);
    }
  }


  const loadingModal = (companyName) => {
    return (<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full items-end justify-center p-16 text-center sm:items-center sm:p-16">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="">

                    <div className="mt-3 text-center sm:mt-0 ">
                      <Dialog.Title as="h1" className="text-2xl font-semibold leading-6 text-gray-900 text-center animate-pulse">
                        Finding People.....  <br /><br />


                      </Dialog.Title>
                      <div className="mt-2 pb-6 mb-7 -ml-7">
                        <PropagateLoader

                          loading={loading}

                          size={30}
                          color="#4f46e5"
                          margin={4}

                        />
                      </div>
                    </div>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>)
  }


  return (
    <Layout title="Find people based on companies and job roles. ">
      <Form handleProfiles={handleProfiles} />
      {open && loadingModal(companyName)}


      <div ref={myRef}>
     
      
      {loadingResult ? leads.length === 0 ? <div className='mt-4 text-center w-full text-lg font-bold '>Sorry, there are no LinkedIn profiles associated with the company <span className='text-red-900'>{companyName}</span>.</div> : <Table leads={leads} companyName={companyName} /> : ""}
    
      </div>
    </Layout>
  )
}

export default FindPeople
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LOGO from '../assets/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _, { set } from "lodash"

const Register = () => {
  let navigate = useNavigate();
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const [users, setUsers] = useState([])
  const [availableStatus, setAvailableStatus] = useState()
  const [cursorStatus, setCursorStatus] = useState()



  useEffect(() => {
    const checkUsers = async () => {
      try {
        const response = await fetch(`/api/users`, {
          method: 'GET',
          redirect: 'follow'
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data);
          setUsers(data)

          // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
          // setCompanies(totalCompanies)

        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    };

    checkUsers();
  }, [])


  const handleFirstName = e => setFirstName(e.target.value)
  const handleLastName = e => setLastName(e.target.value)

  const handleEmail = e => {

    let filterUser = _.filter(users, ['email', e.target.value])

    if (filterUser.length === 0) {
      setAvailableStatus(<span className=' text-xs font-bold text-green-900'>Email is available. Continue to register.</span>);
      document.getElementById("submitBtn").disabled = false;
      setCursorStatus("cursor-pointer")
      setEmail(e.target.value)
    } else if (filterUser.length === 1) {
      setAvailableStatus(<span className=' text-xs font-bold text-red-900'>Email already registered. Try logging in.</span>);
      document.getElementById("submitBtn").disabled = true;
      setCursorStatus("cursor-not-allowed")
    } else {
      setAvailableStatus("")
      document.getElementById("submitBtn").disabled = false;
      setEmail(e.target.value)
    }





  }


  const handlePassword = e => setPassword(e.target.value)





  const handleRegister = async (e) => {
    e.preventDefault();

    const userObject = {
      firstName,
      lastName,
      email,
      password
    }

    console.log(userObject)


    // Check if the email domain is decisionfoundry.com
    if (email.endsWith('@decisionfoundry.com')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userObject),
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data);
          navigate('/registration-successful');
        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    } else {
      toast.error('Only @decisionfoundry.com emails are allowed for registration.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }


  }



  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src={LOGO}
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Register
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={handleRegister}>
          <div>

            <div className="mt-2">
              <input
                onChange={handleFirstName}
                name="text"
                type="firstName"
                autoComplete="firstName"
                placeholder="First Name"
                required
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>

            <div className="mt-2">
              <input
                onChange={handleLastName}
                name="lastName"
                type="text"
                autoComplete="lastName"
                placeholder='Last Name'
                required
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>

            <div className="mt-2">
              <input
                onChange={handleEmail}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Email"
                required
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
            {availableStatus}
          </div>

          <div>

            <div className="mt-2">
              <input
                onChange={handlePassword}
                id="password"
                name="password"
                type="password"
                placeholder='Password'
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              id="submitBtn"
              className={`${cursorStatus} flex w-full justify-center rounded-md bg-slate-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600`}
            >
              Register
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already a member?{' '}
          <Link to="/" className="font-semibold leading-6 text-slate-600 hover:text-slate-500">
            Login
          </Link>
        </p>
      </div>
      <ToastContainer />
      
    </div>
  )
}

export default Register
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';







import { Provider } from "react-redux";
import store from './store';

import AuthLayout from './AuthLayout';


import Login from './pages/Login';
import Register from './pages/Register';
import RegistrationSuccessful from './pages/RegistrationSuccessful';
import ConfirmEmail from './pages/ConfirmEmail';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordMessage from './pages/ResetPasswordMessage';
import NewPassword from './pages/NewPassword';
import PasswordUpdatedSuccessfully from './pages/PasswordUpdatedSuccessfully';
import Dashboard from './pages/Dashboard';
import Scraping from './pages/Scraping';
import FindPeople from './pages/FindPeople';
import JobList from './pages/JobList'
import ProfileList from './pages/ProfileList';
import Help from './pages/Help';
import YourProfile from './pages/YourProfile';
import Settings from './pages/Settings';









function App() {
  return (
    <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/registration-successful" element={<RegistrationSuccessful />} />
        <Route path="/confirm-email/:id" element={<ConfirmEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password-message" element={<ResetPasswordMessage />} />
        <Route path="/new-password/:token" element={<NewPassword />} />
        <Route path="/password-updated-successfully" element={<PasswordUpdatedSuccessfully />} />
        
            <Route path="/home" element={<AuthLayout><Dashboard /></AuthLayout>} />
            <Route path="/findcompanies" element={<AuthLayout><Scraping /></AuthLayout>} />
            <Route path="/findpeople" element={<AuthLayout><FindPeople /></AuthLayout>} />
            <Route path="/job/:id" element={<AuthLayout><JobList /></AuthLayout>} />
            <Route path="/profile/:id" element={<AuthLayout><ProfileList /></AuthLayout>} />
            <Route path="/help" element={<AuthLayout><Help /></AuthLayout>} />
            <Route path="/my-profile" element={<AuthLayout><YourProfile /></AuthLayout>} />
            <Route path="/settings" element={<AuthLayout><Settings /></AuthLayout>} />
        



      </Routes>
    </Router>
    </Provider>
  );
}

export default App;

import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const options = [
  { value: 'FULLTIME', label: 'FULLTIME' },
  { value: 'CONTRACTOR', label: 'CONTRACTOR' },
  { value: 'PARTTIME', label: 'PARTTIME' }

]

const Form = ({handleJobs}) => {
  const [agreed, setAgreed] = useState(false)
  const [jobTitle, setJobTitle] = useState()
  const [country, setCountry] = useState("USA")
  const [jobDatePosted, setJobDatePosted] = useState()
  const [remoteJobOnly, setRemoteJobOnly] = useState()
  const [employementType, setEmployementType] = useState(null)
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState([])


  const handleJobTitle = e => setJobTitle(e.target.value)
  const handleCountry = e => setCountry(e.target.value)
  const handleJobDatePosted = e => setJobDatePosted(e.target.value)
  const handleRemoteJobOnly = e => setRemoteJobOnly(e.target.value)
  const handleEmployementType = option => setEmployementType(option)


  // const onSubmitFindJobs = async (e) => {
  //   e.preventDefault();

  //   console.log("Jobtitle:", jobTitle)
  //   console.log("country:", country)
  //   console.log("jobDatePosted:", jobDatePosted)
  //   console.log("remoteJobOnly:", remoteJobOnly)
  //   console.log("employementType:", employementType)

  //   // let apiURL;

  //   // if (jobDatePosted === undefined && remoteJobOnly=== undefined) {
  //   //   apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20`
  //   // } else if (remoteJobOnly=== undefined) {
  //   //   apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20&date_posted=${jobDatePosted===undefined?"":jobDatePosted}`
  //   // } else if (jobDatePosted=== undefined) {
  //   //   apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20&remote_jobs_only=${remoteJobOnly===undefined?"":remoteJobOnly}`
  //   // } else {
  //   //   apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20&date_posted=${jobDatePosted===undefined?"":jobDatePosted}&remote_jobs_only=${remoteJobOnly===undefined?"":remoteJobOnly}`;
  //   // }


  //   // const url = apiURL;
  //   // const options = {
  //   //   method: 'GET',
  //   //   headers: {
  //   //     'X-RapidAPI-Key': 'ea4507803bmsh28397e1ce8773a4p1c95e8jsn0fff31110b37',
  //   //     'X-RapidAPI-Host': 'jsearch.p.rapidapi.com'
  //   //   }
  //   // };

  //   // try {
  //   //   const response = await fetch(url, options);
  //   //   const result = await response.text();
  //   //   console.log("Jobs",result);
  //   //   setJobs(result.data)
  //   //   setTimeout(()=>{
  //   //     handleJobs(result.data)
  //   //   },3000)
     
  //   // } catch (error) {
  //   //   console.error(error);
  //   // }

  // }

  return (
    <div className="isolate bg-white px-6  lg:px-8 shadow-lg py-5">


      
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Enter Job title
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="jobTitle"
                id="jobTitle"

                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={handleJobTitle}
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Country
            </label>
            <div className="mt-2.5">

              <select
                name='country'
                className=" cursor-pointer block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={handleCountry}
              >
                <option value={`USA`}>USA</option>
                <option value={`Canada`}>Canada</option>
                <option value={`UK`}>UK</option>
                <option value={`India`}>India</option>
                <option value={`Australia`}>Australia</option>
                <option value={`Singapore`}>Singapore</option>
                <option value={`UAE`}>UAE</option>
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Job Date Posted
            </label>
            <div className="mt-2.5">

              <select
                name='date_posted'
                className=" cursor-pointer block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={handleJobDatePosted}
              >
                
                <option value={`all`}>All</option>
                <option value={`today`}>Today</option>
                <option value={`3days`}>Last 3 Days</option>
                <option value={`week`}>Week</option>
                <option value={`month`}>Month</option>

              </select>
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Remote Jobs Only
            </label>
            <div className="mt-2.5">
              <select
                name='remote_jobs_only'
                className=" cursor-pointer block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={handleRemoteJobOnly}
              >
                <option value={``}>All</option>
                <option value={`true`}>True</option>
                <option value={`false`}>False</option>


              </select>
            </div>
          </div>
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Employement Types <span className='text-xs text-gray-500'>(FULLTIME,CONTRACTOR,PARTTIME)</span>
            </label>
            <div className="mt-2.5">
              <Select options={options}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                className="block w-full rounded-md border-0 px-0.5 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={handleEmployementType}
                value={employementType}
                placeholder="All"
              />
            </div>
          </div>
          







        </div>
        <div className="mt-10">
          <button
            type="button"
            className="block  rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={()=>handleJobs(jobTitle,country,jobDatePosted,remoteJobOnly,employementType,loading)}
          >
            FIND COMPANIES
          </button>
        </div>
      
    </div>
  )
}

export default Form
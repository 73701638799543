import React from 'react'
import { Link } from 'react-router-dom'
import LOGO from '../assets/logo.png'

const PasswordUpdatedSuccessfully = () => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="w-full">
          <img
            className="mx-auto h-10 w-auto"
            src={LOGO}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Your password has been updated successfully! You can now log in with your new password. If you have any questions or need further assistance, please don't hesitate to contact our support team at leadline@decisonfoundry.com. Thank you for using Leadline!

          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
         

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?{' '}
            <Link to="/" className="font-semibold leading-6 text-slate-600 hover:text-slate-500">
              Login
            </Link>
          </p>
        </div>
      </div>
  )
}

export default PasswordUpdatedSuccessfully
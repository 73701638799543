import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import LOGO from '../assets/logo.png'
import AVATAR from '../assets/avatar.png'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../actions';
import jwt from 'jwt-decode'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Layout = ({children, title}) => {
  const { token } = useSelector((state) => state.auth);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState()
    
    console.log(location.pathname);

    console.log(jwt(token.split(" ")[1]))


    useEffect (()=>{
      //setUser(jwt(token.split(" ")[1]))
      let user = jwt(token.split(" ")[1])
      
      const checkUser = async (id) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/${id}`, {
            method: 'GET',
            redirect: 'follow'
          });
  
          const data = await response.json();
  
          if (response.ok) {
            // the HTTP response status code is 2xx
            // update your state with the data or do something else
            console.log("User Data",data);
            setUser(data)
            
  
            // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
            // setCompanies(totalCompanies)
  
          } else {
            // the HTTP response status code is not 2xx
            // handle the error
            console.error(data);
          }
        } catch (error) {
          // handle the error
          console.error('Error:', error);
        }
      };
  
      
      checkUser(user.id);



    },[token])
    const userDetails = {
        name: user&&user.firstName,
        email: user&&user.email,
        imageUrl: user?.profilePic === "" ? AVATAR : user?.profilePic,
      }
      const navigation = [
        { name: 'Home', href: '/home', current: location.pathname==='/home'?true:false },
        { name: 'Find Companies', href: '/findcompanies', current: location.pathname==='/findcompanies'?true:false },
        { name: 'Find People', href: '/findpeople', current: location.pathname==='/findpeople'?true:false }
      //   { name: 'Calendar', href: '#', current: false },
      //   { name: 'Reports', href: '#', current: false },
      ]
      const userNavigation = [
        { name: 'Your Profile', href: '/my-profile' },
        
        
      ]


      const handleLogout = () => {
        dispatch(logOut());
        navigate('/', { replace: true });
      }
    
  return (
    <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="mx-auto h-10 w-auto"
                        src={LOGO}
                        alt="Leadline"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <Link
                        to="/help"
                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                       
                        <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
                      </Link>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={userDetails.imageUrl} alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                            <Menu.Item>
                              <button onClick={handleLogout} className={classNames(
                                      
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}>Signout</button>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={userDetails.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{userDetails.name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{userDetails.email}</div>
                    </div>
                    <Link
                      to="/help"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      
                      <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </Link>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="Link"
                        to={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {location.pathname==='/home'?"":
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-xl font-bold tracking-tight text-gray-900">{title}</h1>
          </div>
        </header>}
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {location.pathname==='/home'?<div className="mx-auto max-w-7xl py-2">
            <h3 className=' text-xl font-medium text-gray-600'>Greetings, <span className='text-blue-800 font-bold'>{user&&user.firstName}</span>. Below is a brief overview of the organization-wide tasks.</h3>
          </div>:""}
            {children}
          </div>
        </main>
      </div>
  )
}

export default Layout
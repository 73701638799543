import React from 'react'
import Layout from '../components/Layout'


const Settings = () => {
  return (
    <Layout title="Settings">
        <h1>Settings content will come here</h1>
    </Layout>
  )
}

export default Settings
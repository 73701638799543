import React from 'react'
import Layout from '../components/Layout'
import LeadlineWalkthough from '../components/help/LeadlineWalkthough'



const Help = () => {
  // const findCompaniesVideo = "https://res.cloudinary.com/ddv9pyxai/video/upload/v1694157948/Leadline/find_companies_leadline_sm4ajq.mkv";
  // const findPeopleVideo = "https://res.cloudinary.com/ddv9pyxai/video/upload/v1694157945/Leadline/find_people_leadline_vtgbn0.mkv";

  return (
    <Layout title="Leadline Walkthough">
        <div className='w-[600px] mx-auto my-3 mb-12 items-center justify-center'>
       
          <LeadlineWalkthough />
        </div>

       


    </Layout>
  )
}

export default Help
import {useEffect, useState} from 'react'
import _ from 'lodash'
import CardLoading from '../common/CardLoading'

const Stat = () => {
    const [jobs, setJobs] = useState([])
    const [profiles, setProfiles] = useState([])
    const [companies, setCompanies] = useState(0)
    const [people, setPeople] = useState(0)
    const [loadingCard, setLoadingCard] = useState(true)


    useEffect(()=>{
      
        const checkJobs = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs`, {
                  method: 'GET',
                  redirect: 'follow'
                });
          
                const data = await response.json();
          
                if (response.ok) {
                  // the HTTP response status code is 2xx
                  // update your state with the data or do something else
                  console.log(data);
                  setJobs(data)

                  const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
                  setCompanies(totalCompanies)

                  setLoadingCard(false)
                  
                } else {
                  // the HTTP response status code is not 2xx
                  // handle the error
                  console.error(data);
                }
              } catch (error) {
                // handle the error
                console.error('Error:', error);
              }
          };
    
          checkJobs();

          const checkProfiles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/savedProfiles`, {
                  method: 'GET',
                  redirect: 'follow'
                });
          
                const data = await response.json();
          
                if (response.ok) {
                  // the HTTP response status code is 2xx
                  // update your state with the data or do something else
                  console.log(data);
                  setProfiles(data)

                  const totalPeople = _.reduce(data, (count, item) => count + item.profiles.length, 0);
                  setPeople(totalPeople)

                  setLoadingCard(false)
                  
                } else {
                  // the HTTP response status code is not 2xx
                  // handle the error
                  console.error(data);
                }
              } catch (error) {
                // handle the error
                console.error('Error:', error);
              }
          };
    
          checkProfiles();

    },[])


    return (

        <div className="max-w-[85rem]  mx-auto">

            <div className="mt-4 grid gap-14 grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-12">
               
               {loadingCard?
                  <CardLoading /> :  <div className='shadow-md p-4 bg-gray-100 text-center cursor-pointer hover:bg-gray-50 hover:shadow-lg'>
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-800 ">Job Queries</h4>
                  <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-blue-500 hover:text-red-900">{jobs.length}</p>
                  
              </div>
              }
             

             {loadingCard?
                  <CardLoading /> :  <div className='shadow-md p-4 bg-gray-100 text-center cursor-pointer hover:bg-gray-50 hover:shadow-lg'>
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-800 ">Total Companies</h4>
                  <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-blue-500 hover:text-red-900">{companies}</p>
                  
              </div>
              }

             {loadingCard?
                  <CardLoading /> :   <div className='shadow-md p-4 bg-gray-100 text-center cursor-pointer hover:bg-gray-50 hover:shadow-lg'>
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-800 ">Total People</h4>
                  <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-blue-500 hover:text-red-900">{people}</p>
                  
              </div>
              }
                



               

            </div>

        </div>

    )
}

export default Stat
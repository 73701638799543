import React from 'react'
import { Link } from 'react-router-dom'
import LOGO from '../assets/logo.png'

const RegistrationSuccessful = () => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="w-full">
          <img
            className="mx-auto h-10 w-auto"
            src={LOGO}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          Thank you for completing the registration. An email has been sent to the address you provided during registration. To confirm your email address, please click on the link contained within this email.
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
         

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?{' '}
            <Link to="/" className="font-semibold leading-6 text-slate-600 hover:text-slate-500">
              Login
            </Link>
          </p>
        </div>
      </div>
  )
}

export default RegistrationSuccessful
import { useEffect, useState } from 'react'
import Papa from 'papaparse';
import _ from "lodash"
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import TableLoading from '../common/TableLoading';

const Jobs = () => {

  const [jobs, setJobs] = useState([])
  const [users, setUsers] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [activeTab, setActiveTab] = useState('Companies')



  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);



  // For profiles

  const [profiles, setProfiles] = useState([])
  const [searchQuery2, setSearchQuery2] = useState('');
  const [currentPage2, setCurrentPage2] = useState(1);
  const [itemsPerPage2] = useState(10);





  useEffect(() => {
    const checkJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs`, {
          method: 'GET',
          redirect: 'follow'
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data);
          setJobs(_.reverse(data))
          setLoadingTable(false)

          // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
          // setCompanies(totalCompanies)

        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    };

    checkJobs();


    const checkUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users`, {
          method: 'GET',
          redirect: 'follow'
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data);
          setUsers(data)

          // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
          // setCompanies(totalCompanies)

        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    };

    checkUsers();


    const checkProfiles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/savedProfiles`, {
          method: 'GET',
          redirect: 'follow'
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data);
          setProfiles(_.reverse(data))
          setLoadingTable(false)

          // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
          // setCompanies(totalCompanies)

        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    };

    checkProfiles();
  }, [])



  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = jobs.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);



  // For Profiles

  // Get current items
  const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  const currentItems2 = profiles.slice(indexOfFirstItem2, indexOfLastItem2);

  // Change page
  const paginate2 = pageNumber2 => setCurrentPage2(pageNumber2);


  const handleFilterByUser = async (e) => {

    console.log('e.target.value', e.target.value)

    try {
      const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs`, {
        method: 'GET',
        redirect: 'follow'
      });

      const data = await response.json();

      if (response.ok) {
        // the HTTP response status code is 2xx
        // update your state with the data or do something else
        console.log(data);

        if (e.target.value === '') {
          setJobs(_.reverse(data))
        } else {

          let filterJobs = _.filter(data, ['user', e.target.value])

          setJobs(_.reverse(filterJobs))
        }

        // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
        // setCompanies(totalCompanies)

      } else {
        // the HTTP response status code is not 2xx
        // handle the error
        console.error(data);
      }
    } catch (error) {
      // handle the error
      console.error('Error:', error);
    }



  }


  const downloadCSV = (id) => {

    // filter Jobs based on id

    let filterJob = _.filter(jobs, ['_id', id]);





    const csv = Papa.unparse(filterJob[0].companies);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leadline.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }




    return (
      <nav>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className={`py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm  ${number === currentPage ? 'bg-black text-white' : ''}`}>
              <button onClick={() => paginate(number)} className='page-link'>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };


  let filteredJobs = currentItems;
  if (searchQuery) {
    filteredJobs = currentItems.filter(job =>
      (job.job_title ? job.job_title.toLowerCase().includes(searchQuery.toLowerCase()) : false)

    );
  }


  // For Profiles

  const Pagination2 = ({ itemsPerPage, totalItems, paginate2 }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }




    return (
      <nav>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className={`py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm  ${number === currentPage ? 'bg-black text-white' : ''}`}>
              <button onClick={() => paginate(number)} className='page-link'>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };


  let filteredJobs2 = currentItems2;
  if (searchQuery2) {
    filteredJobs2 = currentItems2.filter(profile =>
      (profile.company_name ? profile.company_name.toLowerCase().includes(searchQuery2.toLowerCase()) : false)

    );
  }



  // For Profiles

  const handleFilterByUser2 = async (e) => {

    console.log('e.target.value', e.target.value)

    try {
      const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/savedProfiles`, {
        method: 'GET',
        redirect: 'follow'
      });

      const data = await response.json();

      if (response.ok) {
        // the HTTP response status code is 2xx
        // update your state with the data or do something else
        console.log(data);

        if (e.target.value === '') {
          setProfiles(_.reverse(data))
        } else {

          let filterJobs = _.filter(data, ['user', e.target.value])

          setProfiles(_.reverse(filterJobs))
        }

        // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
        // setCompanies(totalCompanies)

      } else {
        // the HTTP response status code is not 2xx
        // handle the error
        console.error(data);
      }
    } catch (error) {
      // handle the error
      console.error('Error:', error);
    }



  }


  const downloadCSV2 = (id) => {

    // filter Jobs based on id

    let filterJob = _.filter(profiles, ['_id', id]);





    const csv = Papa.unparse(filterJob[0].profiles);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leadline.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }



  const savedJobs = () => {

    return loadingTable ?
      <TableLoading /> :
      (<div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">

              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200">

                <div className="sm:col-span-1">
                  <label for="hs-as-table-product-review-search" className="sr-only">Search</label>
                  <div className="relative">
                    <input type="text" id="hs-as-table-product-review-search" name="hs-as-table-product-review-search" className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 " placeholder="Search by Job title" value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)} />
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                      <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-2 md:grow">
                  <div className="flex justify-end gap-x-2">

                    <select
                      name='user'
                      className='text-sm text-gray-800 p-1 w-52 cursor-pointer'
                      onChange={handleFilterByUser}
                    >
                      <option value=''>Filter by user</option>
                      {_.map(users, (user) => {
                        return (<option key={user._id} value={user._id}>{user.firstName} {user.lastName}</option>)
                      })}
                    </select>

                  </div>
                </div>
              </div>

              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50">
                  <tr>


                    <th scope="col" className="pl-6 py-3 text-center">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Job Id
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Date
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          User
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Job Title
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Country
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Companies
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-right"></th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 ">

                  {_.map(filteredJobs, (job) => {

                    let filterUser = _.filter(users, ['_id', job.user])

                    return (
                      <tr key={job._id}>

                        <td className="h-px w-px whitespace-nowrap">
                          <div className="pl-6 py-2">
                            <Link to={`/job/${job._id}`} className="text-sm text-blue-600 decoration-2 hover:underline " >{job.jobId}</Link>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <span className="text-sm text-gray-600 "><Moment>{job.createdAt}</Moment></span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <span className="text-sm text-gray-600 ">{filterUser[0].firstName} {filterUser[0].lastName}</span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <span className="text-sm text-gray-600 ">{job.job_title}</span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <div className="flex items-center gap-x-2">
                              <span className="text-sm text-gray-600 ">{job.country}</span>
                            </div>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <div className="flex items-center gap-x-2">
                              <span className="text-sm text-gray-600 ">{job.companies.length}</span>
                            </div>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-1.5 flex justify-end">
                            <div className="group inline-flex items-center divide-x divide-gray-300 border border-gray-300 bg-white shadow-sm rounded-md transition-all">
                              <div className="hs-tooltip inline-block">
                                <button onClick={() => downloadCSV(job._id)} className="hs-tooltip-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-l-md bg-white text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm " href="#">
                                  <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                  <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm " role="tooltip">
                                    Download PDF
                                  </span>
                                </button>
                              </div>

                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  }
















                </tbody>
              </table>



              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                {jobs.length > 10 ?
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={jobs.length}
                    paginate={paginate}
                  />
                  : ""}
              </div>

            </div>
          </div>
        </div>
      </div>)

  }


  const savedProfile = () => {

    return loadingTable ?
      <TableLoading /> :
      (<div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">

              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">

                <div className="sm:col-span-1">
                  <label for="hs-as-table-product-review-search" className="sr-only">Search</label>
                  <div className="relative">
                    <input type="text" id="hs-as-table-product-review-search" name="hs-as-table-product-review-search" className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500  " placeholder="Search by Job title" value={searchQuery}
                      onChange={e => setSearchQuery2(e.target.value)} />
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                      <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-2 md:grow">
                  <div className="flex justify-end gap-x-2">

                    <select
                      name='user'
                      className='text-sm text-gray-800 p-1 w-52 cursor-pointer'
                      onChange={handleFilterByUser2}
                    >
                      <option value=''>Filter by user</option>
                      {_.map(users, (user) => {
                        return (<option key={user._id} value={user._id}>{user.firstName} {user.lastName}</option>)
                      })}
                    </select>

                  </div>
                </div>
              </div>

              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>


                    <th scope="col" className="pl-6 py-3 text-center">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Save Profiles Id
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Date
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          User
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Company Name
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Country
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Linkedin Profiles
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-right"></th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 ">

                  {_.map(filteredJobs2, (job) => {

                    let filterUser = _.filter(users, ['_id', job.user])

                    return (
                      <tr key={job._id}>

                        <td className="h-px w-px whitespace-nowrap">
                          <div className="pl-6 py-2">
                            <Link to={`/profile/${job._id}`} className="text-sm text-blue-600 decoration-2 hover:underline " >{job.profileId}</Link>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <span className="text-sm text-gray-600 "><Moment>{job.createdAt}</Moment></span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <span className="text-sm text-gray-600 ">{filterUser[0].firstName} {filterUser[0].lastName}</span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <span className="text-sm text-gray-600 ">{job.company}</span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <div className="flex items-center gap-x-2">
                              <span className="text-sm text-gray-600 ">{job.country}</span>
                            </div>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-2">
                            <div className="flex items-center gap-x-2">
                              <span className="text-sm text-gray-600 ">{job.profiles.length}</span>
                            </div>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-1.5 flex justify-end">
                            <div className="group inline-flex items-center divide-x divide-gray-300 border border-gray-300 bg-white shadow-sm rounded-md transition-all  ">
                              <div className="hs-tooltip inline-block">
                                <button onClick={() => downloadCSV2(job._id)} className="hs-tooltip-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-l-md bg-white text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm " href="#">
                                  <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                  <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm " role="tooltip">
                                    Download PDF
                                  </span>
                                </button>
                              </div>

                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  }
















                </tbody>
              </table>



              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                {profiles.length > 10 ?
                  <Pagination2
                    itemsPerPage={itemsPerPage2}
                    totalItems={profiles.length}
                    paginate={paginate2}
                  />
                  : ""}
              </div>

            </div>
          </div>
        </div>
      </div>)

  }



  const showPeople = () => setActiveTab("People")


  const showCompanies = () => setActiveTab("Companies")

  return (

    <div className="w-full  py-10  lg:py-14 ">
      <div className='flex gap-3 mb-3'>

        <h1 className='text-2xl mb-3  text-blue-800 font-semibold'>Saved Queries</h1>

        <button
          onClick={showCompanies}
          type="button"
          className={activeTab === "Companies" ? "py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-800 text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 transition-all text-sm " : "py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm "}
        >
          Companies
        </button>

        <button
          onClick={showPeople}
          type="button"
          className={activeTab === "People" ? "py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-800 text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 transition-all text-sm " : "py-1 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm "}
        >
          People
        </button>

      </div>
      {
        activeTab === "Companies" ? savedJobs() : (activeTab === "People" ? savedProfile() : null)
      }


    </div>

  )
}

export default Jobs
import React from 'react';

function LeadlineWalkthough() {
  return (
    <div style={{ maxWidth: '600px' }}>
      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
        <iframe
          src="https://nablerin-my.sharepoint.com/personal/bia_projects_decisionfoundry_com/_layouts/15/embed.aspx?UniqueId=2bf72c37-b39c-4976-b976-92cae39fabba&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
          width="600"
          height="300"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          title="Leadline Walkthough"
          style={{ border: 'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: '100%', maxWidth: '100%' }}
        />
      </div>
    </div>
  );
}

export default LeadlineWalkthough;

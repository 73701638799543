import React from 'react'

const CardLoading = () => {
    return (
       
            <div className="animate-pulse flex space-x-4">
               
                <div className="flex-1 space-y-4 py-1">
                    <div className=" h-28  bg-gray-400 rounded w-full"></div>
                    
                </div>
            </div>
        
    )
}

export default CardLoading
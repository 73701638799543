import { useState, useEffect } from 'react'
import _ from "lodash"
import axios from 'axios'
import Select from 'react-select';




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




const Form = ({ handleProfiles }) => {

  const [jobs, setJobs] = useState([])
  const [companies, setCompanies] = useState([])
  const [companyId, setCompanyId] = useState()
  const [companyName, setCompanyName] = useState()
  const [country, setCountry] = useState()
  const [role, setRole] = useState()
  const [roleArray, setRoleArray] = useState([])
  const [linkedinCompanies, setLinkedinCompanies] = useState(0)
  const [showMessage, setShowMessage] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true);
  const [countPeople, setCountPeople] = useState(0)


  useEffect(() => {

    const checkJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs`, {
          method: 'GET',
          redirect: 'follow'
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data);
          setJobs(_.reverse(data))







          // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
          // setCompanies(totalCompanies)

        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    };

    checkJobs();
  }, [])


  
    const checkProfiles = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/savedProfiles`, {
            method: 'GET',
            redirect: 'follow'
          });
    
          const data = await response.json();
    
          if (response.ok) {
            // the HTTP response status code is 2xx
            // update your state with the data or do something else
            console.log(data);
            setCountPeople(data.length)

            
            
          } else {
            // the HTTP response status code is not 2xx
            // handle the error
            console.error(data);
          }
        } catch (error) {
          // handle the error
          console.error('Error:', error);
        }
    };

    


  const handleJob = async (e) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs/${e.target.value}`, {
        method: 'GET',
        redirect: 'follow'
      });

      const data = await response.json();

      if (response.ok) {
        // the HTTP response status code is 2xx
        // update your state with the data or do something else
        console.log(data.companies);

        let employersList = [];

        _.map(data.companies, (company) => {
          employersList.push(company.employer_name)
        })

        setCompanies(_.uniq(employersList))
        setCountry(data.country)





        // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
        // setCompanies(totalCompanies)

      } else {
        // the HTTP response status code is not 2xx
        // handle the error
        console.error(data);
      }
    } catch (error) {
      // handle the error
      console.error('Error:', error);
    }
  }

  const getRandomKey = () => {
    const maxKeyNumber = 3; // Replace with the number of keys you have
    const randomKeyNum = Math.floor(Math.random() * maxKeyNumber) + 1; // Generate a random number between 1 and maxKeyNumber
    return `REACT_APP_LPROFILE_KEY${randomKeyNum}`;
  }

  const randomKey = getRandomKey();
  console.log("randomKey", process.env[randomKey])


  const handleCompanies = async (e) => {
    setCompanyId("");
      setCompanyName("")

      checkProfiles();

    const options = {
      method: "GET",
      url: "https://generate-linkedin-leads.p.rapidapi.com/backend/leads/search-companies/",
      params: {
        name: e.target.value,
      },
      headers: {
        "X-RapidAPI-Key": process.env[randomKey],
        "X-RapidAPI-Host": "generate-linkedin-leads.p.rapidapi.com",
      },
    };

    console.log("options", options)

    try {
      const response = await axios.request(options);
      console.log("company key:", response.data);

      let companyDataArray = response.data;

      setLinkedinCompanies(companyDataArray.length);

      if (companyDataArray.length === 0) {
        setShowMessage(true)
      } else {
        setShowMessage(true)
        setIsDisabled(false)
      }

      if (companyDataArray.length > 1) {
        let getcompanyExtactdetail = _.filter(companyDataArray, {
          value: e.target.value,
        });

        console.log('Company Id: ', getcompanyExtactdetail[0].id);

        setCompanyId(getcompanyExtactdetail[0].id);
        setCompanyName(e.target.value)
      } else {
        setCompanyId(companyDataArray[0].id);
        setCompanyName(e.target.value)
      }


    } catch (error) {
      console.error(error);
    }
  }
  const handleRole = e => {
    setRole(e.target.value)
    let roleArray = [];

    if (role === 'Analytics Leader') {
      roleArray = [
        {
          value: "manager analytics",
          label: "Manager Analytics",
        },
        {
          value: "vp analytics",
          label: "VP Analytics",
        },
        {
          value: "director analytics",
          label: "Director Analytics",
        },
        {
          value: "senior manager analytics",
          label: "Senior Manager Analytics",
        },
        {
          value: "data leader",
          label: "Data Leader",
        },
        {
          value: "analytics manager",
          label: "Analytics Manager",
        },
      ]
    } else {
      roleArray = [
        {
          value: _.lowerCase(role),
          label: role,
        },
      ]
    }

    setRoleArray(roleArray)
  }


  const onRoleSuggestion = (e) => {
    setRole(e.target.value);

    let roleArray = [];

    if (role === 'Analytics Leader') {
      roleArray = [
        {
          value: "manager analytics",
          label: "Manager Analytics",
        },
        {
          value: "vp analytics",
          label: "VP Analytics",
        },
        {
          value: "director analytics",
          label: "Director Analytics",
        },
        {
          value: "senior manager analytics",
          label: "Senior Manager Analytics",
        },
        {
          value: "data leader",
          label: "Data Leader",
        },
        {
          value: "analytics manager",
          label: "Analytics Manager",
        },
      ]
    } else {
      roleArray = [
        {
          value: _.lowerCase(role),
          label: role,
        },
      ]
    }
    setRoleArray(roleArray)
  };


  const jobOptions = jobs.map(job => ({
    value: job._id,
    label: `${job.jobId}-${job.job_title}-${job.country}`
  }));

  const companiesOptions = companies.map(company => ({
    value: company,
    label: company
  }));

  return (
    <div className="isolate bg-white px-6  lg:px-8 shadow-lg py-5">



      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Select Job
          </label>
          <div className="mt-2.5">
            <Select
              options={jobOptions}
              onChange={selectedOption => handleJob({ target: { value: selectedOption.value } })}
              placeholder="Select Job"
              className=" cursor-pointer block w-full rounded-md border-0 px-0.5 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Companies
          </label>
          <div className="mt-2.5">
            <Select
              options={companiesOptions}
              onChange={selectedOption => handleCompanies({ target: { value: selectedOption.value } })}
              placeholder="Select Company"
              className=" cursor-pointer block w-full rounded-md border-0 px-0.5 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

          </div>
          {showMessage?(linkedinCompanies===0?<span className='text-sm text-red-900 font-semibold'>{companyName} is not available on Linkedin</span>:<span className='text-sm font-semibold text-green-800'>Linkedin Id: {companyId}</span>):""}
        </div>


        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Role
          </label>
          <div className="mt-2.5">
            <input
              type='text'
              name='role'
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={handleRole}
              value={role}
            />
          </div>
        </div>


        <div>
          <span className="inline-flex text-xs font-bold text-blue-900 mt-12">
            Role Suggestions
          </span>{" "}
          <button
            className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10"
            type="button"
            value="Analytics Leader"
            onClick={onRoleSuggestion}
          >
            Analytics Leader
          </button>{" "}
          <button
            className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10"
            type="button"
            value="CEO"
            onClick={onRoleSuggestion}
          >
            CEO
          </button>{" "}
          <button
            className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10"
            type="button"
            value="VP"
            onClick={onRoleSuggestion}
          >
            VP
          </button>{" "}
          <button
            className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10"
            type="button"
            value="Senior Manager"
            onClick={onRoleSuggestion}
          >
            Senior Manager
          </button>{" "}
          <button
            className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10"
            type="button"
            value="Director"
            onClick={onRoleSuggestion}
          >
            Director
          </button>
        </div>


           




      </div>
      
      
      <div className="mt-10">
        <button
          type="button"
          className={isDisabled?`block rounded-md bg-gray-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 cursor-not-allowed`:`block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer`}
          onClick={() => handleProfiles(roleArray, companyId, companyName, country, role, countPeople)}
          disabled={isDisabled}
        >
          FIND PEOPLE
        </button>
      </div>

    </div>
  )
}

export default Form
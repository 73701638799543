import React from 'react'

const TableLoading = () => {
    return (
        <div className="animate-pulse">
            <table className="min-w-full bg-gray-200">
                <thead>
                    <tr>
                        <th className="h-8 bg-gray-400 w-1/7 border-2 border-white "></th>
                        <th className="h-8 bg-gray-400 w-1/7 border-2 border-white"></th>
                        <th className="h-8 bg-gray-400 w-1/7 border-2 border-white"></th>
                        <th className="h-8 bg-gray-400 w-1/7 border-2 border-white"></th>
                        <th className="h-8 bg-gray-400 w-1/7 border-2 border-white"></th>
                        <th className="h-8 bg-gray-400 w-1/7 border-2 border-white"></th>
                        <th className="h-8 bg-gray-400 w-1/7 border-2 border-white"></th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>
                    <tr>
                        <td className="h-8 bg-gray-300 border-2 border-white"></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                        <td className="h-8 bg-gray-300 border-2 border-white "></td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}

export default TableLoading
import React from 'react'
import Layout from '../components/Layout'
import Stat from '../components/dashboard/Stat'
import Jobs from '../components/dashboard/Jobs'

const Dashboard = () => {
  return (
    <Layout title="Dashboard">
        <Stat />
        <Jobs />
    </Layout>
  )
}

export default Dashboard
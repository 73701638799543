import {useState, useEffect} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LOGO from '../assets/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewPassword = () => {
  let navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState()
  const [userId, setUserId] = useState()

  useEffect(()=>{
    const checkUserAvailable = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/new_password/token/${token}`, {
              method: 'GET',
              redirect: 'follow'
            });
      
            const data = await response.json();
      
            if (response.ok) {
              // the HTTP response status code is 2xx
              // update your state with the data or do something else
              console.log(data);
              setUserId(data._id)
              
            } else {
              // the HTTP response status code is not 2xx
              // handle the error
              console.error(data);
            }
          } catch (error) {
            // handle the error
            console.error('Error:', error);
          }
      };

      checkUserAvailable();
  },[token])

  const handlePassword = e => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const userObject = {
      id:userId,
      password
      
    }

    console.log(userObject)


    // Check if the email domain is decisionfoundry.com
   
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/update_password/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userObject),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data);
          navigate('/password-updated-successfully');
        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
   
    

  }
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm lg:w-3/5">
          <img
            className="mx-auto h-10 w-auto"
            src={LOGO}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          Please enter your email address to reset your password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
            <div>
             
              <div className="mt-2">
                <input
                 
                  onChange={handlePassword}
                  name="password"
                  type="password"
                  
                  placeholder='New Password'
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-slate-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              >
                Update Password
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Remembered Password ?{' '}
            <Link to="/" className="font-semibold leading-6 text-slate-600 hover:text-slate-500">
              Login
            </Link>
          </p>
        </div>
        <ToastContainer />
      </div>
  )
}

export default NewPassword
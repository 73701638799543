import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import Layout from '../components/Layout'
import Papa from 'papaparse';
import _ from "lodash"
import { FaEye } from 'react-icons/fa';


const JobList = () => {
  const { id } = useParams();
  const [companies, setCompanies] = useState([]);
  const [jobId, setJobId] = useState()
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [publishers, setPublishers] = useState([])

  useEffect(() => {

    const checkJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs/${id}`, {
          method: 'GET',
          redirect: 'follow'
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log(data.companies);
          setCompanies(data.companies)
          setJobId(data.jobId)


          // to get publisher data
          let publishersList = [];

          _.map(data.companies, (company) => {
            publishersList.push(company.job_publisher)
          });

          setPublishers(_.uniq(publishersList))


          // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
          // setCompanies(totalCompanies)

        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    };

    checkJobs();


  }, [id])

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = companies ? companies.slice(indexOfFirstItem, indexOfLastItem) : [];

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);



  const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }




    return (
      <nav>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className={`py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm  ${number === currentPage ? 'bg-black text-white' : ''}`}>
              <button onClick={() => paginate(number)} className='page-link'>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };


  let filteredJobs = currentItems;
  if (searchQuery) {
    filteredJobs = currentItems.filter(job =>
      (job.job_title ? job.job_title.toLowerCase().includes(searchQuery.toLowerCase()) : false)

    );
  }



  const downloadCSV = () => {

    // filter Jobs based on id







    const csv = Papa.unparse(companies);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leadline.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  const handleSearchbyPublisher = async (e) => {
    console.log('e.target.value', e.target.value)

    try {
      const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs/${id}`, {
        method: 'GET',
        redirect: 'follow'
      });

      const data = await response.json();

      if (response.ok) {
        // the HTTP response status code is 2xx
        // update your state with the data or do something else
        console.log(data);

        if (e.target.value === '') {
          setCompanies(data.companies)
        } else {

          let filterJobs = _.filter(data.companies, ['job_publisher', e.target.value])

          setCompanies(filterJobs)
        }

        // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
        // setCompanies(totalCompanies)

      } else {
        // the HTTP response status code is not 2xx
        // handle the error
        console.error(data);
      }
    } catch (error) {
      // handle the error
      console.error('Error:', error);
    }

  }


  const handleSearchbyType = async (e) => {
    console.log('e.target.value', e.target.value)

    try {
      const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs/${id}`, {
        method: 'GET',
        redirect: 'follow'
      });

      const data = await response.json();

      if (response.ok) {
        // the HTTP response status code is 2xx
        // update your state with the data or do something else
        console.log(data);

        if (e.target.value === '') {
          setCompanies(data.companies)
        } else {

          let filterJobs = _.filter(data.companies, ['job_employment_type', e.target.value])

          setCompanies(filterJobs)
        }

        // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
        // setCompanies(totalCompanies)

      } else {
        // the HTTP response status code is not 2xx
        // handle the error
        console.error(data);
      }
    } catch (error) {
      // handle the error
      console.error('Error:', error);
    }

  }

  const truncateString = (str, num) => {
    if (!str) return ''; // handle null or undefined values
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
}

  


  return (
    <Layout title="Job List">

      <div className="w-full  py-10  lg:py-14 ">
        <h1 className='text-2xl mb-3  text-blue-800 font-semibold'>#{jobId}</h1>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">

                  <div className="sm:col-span-1">
                    <label for="hs-as-table-product-review-search" className="sr-only">Search</label>
                    <div className="relative">
                      <input type="text" id="hs-as-table-product-review-search" name="hs-as-table-product-review-search" className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 " placeholder="Search by Job title" value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)} />
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                        <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-1">
                    <label for="hs-as-table-product-review-search" className="sr-only">Search</label>
                    <div className="relative">
                      <select
                        name='publisher'
                        className='text-sm text-gray-800 p-1 w-52 cursor-pointer'
                        onChange={handleSearchbyPublisher}
                      >
                        <option value=''>Filter by Publisher</option>
                        {_.map(publishers, (publisher, index) => {
                          return (<option key={index} value={publisher} className='cursor-pointer'>{publisher}</option>)
                        })}
                      </select>

                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label for="hs-as-table-product-review-search" className="sr-only">Search</label>
                    <div className="relative">
                      <select
                        name='employement_type'
                        className='text-sm text-gray-800 p-1 w-full cursor-pointer'
                        onChange={handleSearchbyType}
                      >
                        <option value=''>Filter by Employement Type</option>

                        <option value="FULLTIME" className='cursor-pointer'>FULLTIME</option>
                        <option value="CONTRACTOR" className='cursor-pointer'>CONTRACTOR</option>
                        <option value="PARTTIME" className='cursor-pointer'>PARTTIME</option>

                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-2 md:grow">
                    <div className="flex justify-end gap-x-2">

                      <button onClick={downloadCSV} className="hs-tooltip-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-l-md bg-white text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm " href="#">
                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                        <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm " role="tooltip">
                          Download PDF
                        </span>
                      </button>

                    </div>
                  </div>
                </div>

                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>


                      <th scope="col" className="pl-6 py-3 text-center">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Job title
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Employer
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Country
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Employement Type
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Publisher
                          </span>
                        </div>
                      </th>



                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 ">

                    {_.map(filteredJobs, (job) => {

                      let employementTypeStyle;
                      if (job.job_employment_type === 'FULLTIME') {
                        employementTypeStyle = <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800  ">FULLTIME</span>
                      } else if (job.job_employment_type === 'CONTRACTOR') {

                        employementTypeStyle = <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-gray-100 text-gray-800  ">CONTRACTOR</span>

                      } else if (job.job_employment_type === 'PARTTIME') {
                        employementTypeStyle = <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-blue-100 text-blue-800  ">PARTTIME</span>
                      }

                      return (
                        <tr key={job._id}>


                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <span className="text-sm text-red-900  italic"><a href={job.job_apply_link} className="text-sm text-blue-600 decoration-2 hover:underline " target='_blank' >{truncateString(job.job_title, 30)}</a></span>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="pl-6 py-2">
                              {job.employer_website?<a href={job.employer_website} className="text-sm text-blue-600 decoration-2 hover:underline " target='_blank' >{job.employer_name}</a>: <span className='text-sm'>{job.employer_name}</span>}
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <span className="text-sm text-gray-600  font-bold">{job.job_country}</span>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <span className="text-sm text-gray-600 ">{employementTypeStyle}</span>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap pr-2">
                            <div className="px-6 py-2">
                              <div className="flex items-center gap-x-2">
                                <a href={job.job_apply_link} className="text-sm text-blue-600 decoration-2 hover:underline " target='_blank' >{job.job_publisher}</a>
                              </div>
                            </div>
                          </td>


                        </tr>
                      )
                    })
                    }
















                  </tbody>
                </table>



                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200">
                  {companies.length > 20 ?
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={companies.length}
                      paginate={paginate}
                    />
                    : ""}
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </Layout>
  )
}

export default JobList
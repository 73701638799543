import { useState, useEffect, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Layout from '../components/Layout'
import Form from '../components/findcompanies/Form'
import Table from '../components/findcompanies/Table'
import PropagateLoader from "react-spinners/PropagateLoader";
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode'
import _ from 'lodash'

// const KEY1 = REACT_APP_JSEARCH_KEY1
// const KEY2 = REACT_APP_JSEARCH_KEY2
// const KEY3 = REACT_APP_JSEARCH_KEY3

const Scraping = () => {
  const { token } = useSelector((state) => state.auth);
  const [user, setUser] = useState()
  let [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([])
  const [open, setOpen] = useState(false)
  const [job_title, setJobTitle] = useState()

  const [countJobs, setCountJobs] = useState(0)
  
  const myRef = useRef(null);

  useEffect(() => {
    setUser(jwt(token.split(" ")[1]))
    console.log('Token in Find companies', jwt(token.split(" ")[1]))
  }, [token])

  useEffect(()=>{
    const checkJobs = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs`, {
            method: 'GET',
            redirect: 'follow'
          });
    
          const data = await response.json();
    
          if (response.ok) {
            // the HTTP response status code is 2xx
            // update your state with the data or do something else
            console.log(data);
            setCountJobs(data.length)
            
          } else {
            // the HTTP response status code is not 2xx
            // handle the error
            console.error(data);
          }
        } catch (error) {
          // handle the error
          console.error('Error:', error);
        }
    };

    checkJobs();
  },[])





  const loadingModal = (jobTitle) => {
    return (<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full items-end justify-center p-16 text-center sm:items-center sm:p-16">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="">

                    <div className="mt-3 text-center sm:mt-0 ">
                      <Dialog.Title as="h1" className="text-2xl font-semibold leading-6 text-gray-900 text-center animate-pulse">
                        Finding Companies .....  <br /><br />


                      </Dialog.Title>
                      <div className="mt-2 pb-6 mb-7 -ml-7">
                        <PropagateLoader

                          loading={loading}

                          size={30}
                          color="#4f46e5"
                          margin={4}

                        />
                      </div>
                    </div>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>)
  }


  const getRandomKey = () => {
    const maxKeyNumber = 3; // Replace with the number of keys you have
    const randomKeyNum = Math.floor(Math.random() * maxKeyNumber) + 1; // Generate a random number between 1 and maxKeyNumber
    return `REACT_APP_JSEARCH_KEY${randomKeyNum}`;
}

const randomKey = getRandomKey();


console.log("randomKey",process.env[randomKey])


  const handleJobs = async (jobTitle, country, jobDatePosted, remoteJobOnly, employementType, loading) => {
    console.log('jobTitle', jobTitle);
    console.log('country', country);
    console.log('jobDatePosted', jobDatePosted);
    console.log('remoteJobOnly', remoteJobOnly);
    console.log('employementType', employementType);
    console.log('loading', loading);
    console.log('user', user);

    setJobTitle(jobTitle)

    setLoading(loading)
    setOpen(true)

    let apiURL;

    if (jobDatePosted === undefined && remoteJobOnly === undefined) {
      apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20`
    } else if (remoteJobOnly === undefined) {
      apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20&date_posted=${jobDatePosted === undefined ? "" : jobDatePosted}`
    } else if (jobDatePosted === undefined) {
      apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20&remote_jobs_only=${remoteJobOnly === undefined ? "" : remoteJobOnly}`
    } else {
      apiURL = `https://jsearch.p.rapidapi.com/search?query=${jobTitle}%2C%20${country}&page=1&num_pages=20&date_posted=${jobDatePosted === undefined ? "" : jobDatePosted}&remote_jobs_only=${remoteJobOnly === undefined ? "" : remoteJobOnly}`;
    }


    const url = apiURL;
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': process.env[randomKey],
        'X-RapidAPI-Host': 'jsearch.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log("Jobs", result);
      if (result.data) {
        setJobs(result.data);

        let jobList = result.data;

        let allJobList = [];

        _.map(jobList, (job) => {
          let newJobObject = {
            job_id: job.job_id,
            job_title: job.job_title,
            job_country: job.job_country,
            employer_name: job.employer_name,
            employer_website: job.employer_website,
            job_publisher: job.job_publisher,
            job_employment_type: job.job_employment_type,
            job_description: job.job_description,
            job_apply_link: job.job_apply_link,
            job_required_skills: job.job_required_skills
          }
          allJobList.push(newJobObject)

        })


        let jobObject = {
          jobId: `JOB-${countJobs+1}`,
          user: user.id,
          job_title: jobTitle,
          country: country,
          companies: allJobList,
          remote: remoteJobOnly,
          job_date_posted: jobDatePosted,
          employment_type: employementType
        };

        console.log("jobObject:", jobObject);


        try {
          const res = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/jobs`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token
            },
            body: JSON.stringify(jobObject),
          });

          const Jobdata = await res.json();

          if (res.ok) {
            // the HTTP response status code is 2xx
            // update your state with the data or do something else
            console.log(Jobdata);

          } else {
            // the HTTP response status code is not 2xx
            // handle the error
            console.error(Jobdata);
          }
        } catch (error) {
          // handle the error
          console.error('Error:', error);
        }

        myRef.current.scrollIntoView({ behavior: 'smooth' });


        setLoading(false)
        setOpen(false)
      } else {
        console.log('Data not found in API response');
        setJobs([]);
        setLoading(true)
        setOpen(true)
      }


    } catch (error) {
      console.error(error);
    }
  }


  return (
    <Layout title="Find companies that are in demand for particular skills or roles. ">
      <Form handleJobs={handleJobs} />

      {open && loadingModal(job_title)}


      <div ref={myRef}>
      {jobs.length === 0 ? "" : <Table jobs={jobs}  />}
      </div>

    </Layout>
  )
}

export default Scraping
import {useState, useEffect, useRef} from 'react'
import Layout from '../components/Layout'
import {FaUserCircle} from 'react-icons/fa'
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const YourProfile = () => {
  const { token } = useSelector((state) => state.auth);
  const fileInputRef = useRef(null);
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [user, setUser] = useState()
  const [currentUser, setCurrentUser] = useState()
  const [profilePic, setProfilePic] = useState()

  const handleFirstName = e => setFirstName(e.target.value)
  const handleLastName = e => setLastName(e.target.value)


  useEffect (()=>{
    setUser(jwt(token.split(" ")[1]))
    let user = jwt(token.split(" ")[1]);

    console.log("user",user)

    const checkUser = async (id) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/${id}`, {
          method: 'GET',
          redirect: 'follow'
        });

        const data = await response.json();

        if (response.ok) {
          // the HTTP response status code is 2xx
          // update your state with the data or do something else
          console.log("User Data",data);
          setCurrentUser(data)
          setFirstName(data.firstName)
          setProfilePic(data.profilePic)
          setLastName(data.lastName)
          setEmail(data.email)

          // const totalCompanies = _.reduce(data, (count, item) => count + item.companies.length, 0);
          // setCompanies(totalCompanies)

        } else {
          // the HTTP response status code is not 2xx
          // handle the error
          console.error(data);
        }
      } catch (error) {
        // handle the error
        console.error('Error:', error);
      }
    };

    
    checkUser(user.id);

  },[token])


 
  const handleButtonClick = () => {
    fileInputRef.current.click();
};

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};


const handleProfilePic = async (e) => {
  const file = e.target.files[0];
  const base64Image = await convertBase64(file);
  console.log("base64Image:", base64Image);
  setProfilePic(base64Image);

  const changeProfilePicObject = {
    profilePic:base64Image
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/addProfilePic/${user.id}`, {
      method: "PUT",
      body: JSON.stringify(changeProfilePicObject),
      headers: { 
        "Content-Type": "application/json",
        'Authorization': token 
      },
    });

    if (response.ok) {
      
    }
  } catch (error) {
    console.log(error);
  }


};


const handleUpdateProfile = async (e) => {
  e.preventDefault();

  const userObject = {
    firstName,
    lastName
    
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/edit/${user.id}`, {
      method: "PUT",
      body: JSON.stringify(userObject),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.ok) {
      toast.success('Updated your details', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  } catch (error) {
    console.log(error);
  }
}

  

  return (
    <Layout title="Your Profile">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div className="max-w-xl mx-auto">
    

    <div className="mt-12">
     
      <form >
        <div className="grid gap-4 lg:gap-6">
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
            <div>
              <label className="block text-sm text-gray-700 font-medium dark:text-white">First Name</label>
              <input type="text" name="firstname"  className="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 " value={firstName} onChange={handleFirstName} />
            </div>

            <div>
              <label  className="block text-sm text-gray-700 font-medium dark:text-white">Last Name</label>
              <input type="text" name="lastname"  className="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 " value={lastName} onChange={handleLastName} />
            </div>
          </div>
          

          <div>
            <label  className="block text-sm text-gray-700 font-medium dark:text-white">Work Email</label>
            <input type="email" name="hs-work-email-hire-us-2"  autocomplete="email" className="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 " readOnly value={email} />
          </div>

          
          <div className="">
          <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                Profile Pic
              </label>
              <div className="mt-2 flex items-center gap-x-3">
                {profilePic === "" ? <FaUserCircle className="h-20 w-20 text-gray-300" aria-hidden="true" /> : <img src={profilePic} className="h-20 w-20 text-gray-300" aria-hidden="true" />}
                <button
                  type="button"
                  onClick={handleButtonClick}
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Upload Profile Pic
                </button>

                <input
                type="file"
               
                ref={fileInputRef}
                onChange={handleProfilePic}
                className="hidden"
            />
              </div>
          </div>
         

          
        </div>
       

       
        
        

        <div className="mt-6 grid">
          <button type="button"  onClick={handleUpdateProfile} className="inline-flex justify-center items-center gap-x-3 text-center bg-gray-900 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800">Update Profile</button>
        </div>

        
      </form>
      
    </div>
  </div>
  <ToastContainer />
</div>
    </Layout>
  )
}

export default YourProfile
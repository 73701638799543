import { useEffect, useState } from 'react'
import _ from "lodash"
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Papa from 'papaparse';



const Table = ({ leads, companyName }) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = leads.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    console.log("Leads in Table", leads)
  }, [leads])


  const handleClick = () => {
    const csv = Papa.unparse(leads);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leadline.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }


    // const jobsList = (currentItems) => {
    //   _.map(currentItems, (job) => {
    //     return(
    //       <tr className="bg-white hover:bg-gray-50 dark:bg-slate-900 dark:hover:bg-slate-800">
    //               <td className="h-px w-px whitespace-nowrap">
    //                {job.job_job_title}

    //               </td>
    //               <td className="h-px w-px whitespace-nowrap">

    //               </td>
    //               <td className="h-px w-72 min-w-[18rem]">

    //               </td>
    //               <td className="h-px w-px whitespace-nowrap">

    //               </td>
    //               <td className="h-px w-px whitespace-nowrap">

    //               </td>
    //               <td className="h-px w-px whitespace-nowrap">

    //               </td>
    //               <td className="h-px w-px whitespace-nowrap">

    //               </td>
    //             </tr>
    //     )
    //   })
    // }

    return (
      <nav>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className={`py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium  text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm  ${number === currentPage ? 'bg-black text-white' : ''}`}>
              <button onClick={() => paginate(number)} className='page-link'>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };


  let filteredJobs = currentItems;
  if (searchQuery) {
    filteredJobs = currentItems.filter(lead =>
      (lead.name ? lead.name.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
      (lead.headline ? lead.headline.toLowerCase().includes(searchQuery.toLowerCase()) : false)
    );
  }


  const truncateString = (str, num) => {
    if (!str) return ''; // handle null or undefined values
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
}



  return (

    <div className="isolate bg-white px-6  lg:px-8 shadow-lg py-5 mt-8">
      <div>

        <h1 className='text-gray-900 text-xl font-bold mb-3'>{leads.length} Linkedin profiles found from {companyName}!</h1>

      </div>

      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">

              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">

                <div className="sm:col-span-1">
                  <label for="hs-as-table-product-review-search" className="sr-only">Search</label>
                  <div className="relative">
                    <input type="text"
                      id="hs-as-table-product-review-search"
                      name="hs-as-table-product-review-search"
                      className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
                      placeholder="Search"
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)} />
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                      <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                  </div>
                </div>


                <div className="sm:col-span-2 md:grow">
                  <div className="flex justify-end gap-x-2">
                    <div className="hs-dropdown relative inline-block [--placement:bottom-right]">
                      <button onClick={handleClick} id="hs-as-table-table-export-dropdown" type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm ">
                        <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                        Export
                      </button>

                    </div>


                  </div>
                </div>
              </div>

              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Name
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Company Name
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left w-20">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Designation
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Location
                        </span>
                      </div>
                    </th>


                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          ACTIONS
                        </span>
                      </div>
                    </th>



                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 ">

                  {_.map(filteredJobs, (job) => {


                    return (
                      <tr className="bg-white hover:bg-gray-50  " key={job._id}>
                        <td className="h-px w-px whitespace-nowrap">




                          <div className="px-6 py-3">
                          <span className="text-xs text-gray-600 ">{job.linkedin_url===null?job.name:<a href={job.linkedin_url} target='_blank' rel="noreferrer" className="text-xs font-semibold text-blue-600 decoration-2 hover:underline mt-5" >{job.name}</a>}</span>
                          </div>

                        </td>
                        <td className="h-px w-px whitespace-nowrap">




                          <div className="px-6 py-3">
                            <span className="text-xs text-gray-600 ">{job.company_name}</span>
                          </div>

                        </td>
                        <td className="h-px w-px whitespace-nowrap">



                          <div className="px-6 py-3">
                            <span className="text-xs text-gray-600 "> {truncateString(job.headline,90)}</span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">


                          <div className="px-6 py-3">
                            <span className="text-xs text-gray-600 "> {job.location}</span>
                          </div>
                        </td>


                        <td className="h-px w-px whitespace-nowrap flex gap-2 text-center">
                          {job.linkedin_url===null?
                          <a href={job.linkedin_url} target='_blank' rel="noreferrer" className='inline-flex items-center gap-1.5 py-3 px-2 rounded-full text-xs font-medium bg-red-100 text-red-800 mt-5'><FaEyeSlash /> No Profile</a>
                          :<a href={job.linkedin_url} target='_blank' rel="noreferrer" className='inline-flex items-center gap-1.5 py-3 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 mt-5'><FaEye /> View Profile</a>}
                        </td>

                      </tr>
                    )
                  }
                  )

                  }






                </tbody>
              </table>



              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">


                <div>
                  <div className="inline-flex gap-x-2">
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={leads.length}
                      paginate={paginate}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Table
import {useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import LOGO from '../assets/logo.png'

const ConfirmEmail = () => {
    const { id } = useParams();

    console.log("Param id:", id);

    useEffect(() => {
        const confirmedEmail = async () => {
          try {
              const response = await fetch(`${process.env.REACT_APP_HOST_NAME}/api/users/confirm-email/${id}`, {
                method: 'PUT',
                redirect: 'follow'
              });
        
              const data = await response.json();
        
              if (response.ok) {
                // the HTTP response status code is 2xx
                // update your state with the data or do something else
                console.log(data);
                
              } else {
                // the HTTP response status code is not 2xx
                // handle the error
                console.error(data);
              }
            } catch (error) {
              // handle the error
              console.error('Error:', error);
            }
        };

        confirmedEmail();
     }, [id]);

  return (
    <div className="min-h-full flex-1 flex-col justify-center  px-6 py-12 lg:px-8  bg">
        <div className="w-2/3 text-center ml-auto mr-auto">
          <img
            className="mx-auto h-10 w-auto"
            src={LOGO}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900 ">
          Your email is confirmed, and your account is now active and ready to use
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
         

          <p className="mt-10 text-center text-sm text-gray-500">
            Please {' '}
            <Link to="/" className="font-semibold leading-6 text-slate-600 hover:text-slate-500">
              Login
            </Link>
          </p>
        </div>
  </div>
  )
}

export default ConfirmEmail